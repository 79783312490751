import React from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import SEO from '../components/meta/SEO';
import Layout from '../components/layout/Layout';
import useGlobalTOCHandler from '../utils/useGlobalTOCHandler';
import renderContent from '../utils/renderContent';
import PageContainer from '../components/layout/PageContainer';

const ContentPageTemplate = ({ pageContext, renderLayout }) => {
  useUserIPGrabber();
  useGlobalTOCHandler();

  const {
    // Display Values
    authors,
    breadcrumbs,
    cosmicEditPath,
    hideAuthors,
    isAutoBreadcrumbs,
    structuredData,
    contentWidth,
    // Content Values
    title,
    readingTime,
    renderContext,
    modifiedAtFormatted,
    // SEO Values
    articleImage,
    canonicalSlug,
    createdAtIso,
    extraMeta,
    metaDescription,
    metaTitle,
    modifiedAtIso,
    noIndex,
    slug,
    contentTopic,
    contentType,
  } = pageContext;

  const publishDateMeta = [
    {
      name: 'article:published_time',
      content: createdAtIso,
    },
    {
      name: 'article:modified_time',
      content: modifiedAtIso,
    },
    {
      name: 'og:updated_time',
      content: modifiedAtIso,
    },
  ];

  // Any data-related changes to page content must also happen in the preview template
  const pageContent = () => (
    <PageContainer
      authors={authors}
      breadcrumbs={!isAutoBreadcrumbs ? breadcrumbs : []}
      cosmicEditPath={cosmicEditPath}
      hideAuthors={hideAuthors}
      publishDate={modifiedAtFormatted}
      readingTime={readingTime}
      title={title}
      containerSize={contentWidth}
    >
      <div className="rich-text">
        {renderContent(renderContext.html, renderContext.context)}
      </div>
    </PageContainer>
  );

  return renderLayout ? (
    <Layout>
      <SEO
        pathname={slug}
        title={metaTitle}
        description={metaDescription}
        canonical={canonicalSlug || slug}
        publishDateMeta={publishDateMeta}
        structuredData={structuredData}
        noIndex={noIndex}
        image={articleImage.url}
        imageHeight={articleImage.height}
        imageWidth={articleImage.width}
        meta={extraMeta}
        addWebsiteName={false}
        contentTopic={contentTopic}
        contentType={contentType}
      />

      {pageContent()}
    </Layout>
  ) : (
    pageContent()
  );
};

ContentPageTemplate.propTypes = {
  pageContext: PropTypes.any,
  renderLayout: PropTypes.bool,
};

ContentPageTemplate.defaultProps = {
  pageContext: {},
  renderLayout: true,
};

export default ContentPageTemplate;
